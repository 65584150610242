import type { UiSchema, RJSFSchema } from "@rjsf/utils";
import i18next from "i18next";
import type { FC, SetStateAction } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { defaultFinderQueryParamsValues } from "../FinderView/defaultFinderQueryParamsValues";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import type { LocalizedString } from "@/components/DataList/DataListRow";
import type Document from "@/declarations/models/Document";
import type DocumentReference from "@/declarations/models/DocumentReference";
import type DocumentStatus from "@/declarations/models/DocumentStatus";
import type { Location } from "@/declarations/models/Location";
import type Schema from "@/declarations/models/Schema";
import { useEditorStore } from "@/EditorContextProvider";
import Loader from "@/framework/Loader";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { useDebounce } from "@/hooks/useDebounce";
import Api from "@/services/Api";
import { useStore } from "@/Store";
import { parseJson } from "@/utils/json";
import { getLanguageCode } from "@/utils/obj";
import { getQueryParams } from "@/utils/url";
import EditDocumentView from "@/views/cms/EditorView/EditDocumentView";
import { useResolvePotentiallyLocalizedString } from "@/hooks/useResolvePotentiallyLocalizedString";

export interface SchemaObject {
  jsonSchema: RJSFSchema;
  uiSchema: UiSchema;
  preview_url: Schema["preview_url"];
  schema_id: Schema["id"];
  schemaName?: Schema["name"];
}

export interface EditDocumentLoaderProps {
  onSave?: (document?: Document) => void;
  schemaId?: number;
  documentIdProp?: number;
  disablePreview?: boolean;
  modalEditor?: boolean;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}

export const EditDocumentLoader: FC<EditDocumentLoaderProps> = ({
  disablePreview = false,
  schemaId,
  documentIdProp,
  onSave,
  modalEditor,
  isDirty,
  setIsDirty,
}) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { state } = useStore();
  const { success, error } = useMessenger();
  const { documentId, instanceSlug, ownerSlug } = useParams();
  const params = getQueryParams(defaultFinderQueryParamsValues);
  const { state: editorState, setDocumentRelations, addDocumentRelations, addDocumentIds } = useEditorStore();
  const applicationInstanceId = state.cmsContextInstance?.id;

  const [selectedLocale, setSelectedLocale] = useState<string>(getLanguageCode(i18next.language));

  const [isLoading, setLoading] = useState(true);
  const getLocalizedString = useResolvePotentiallyLocalizedString(selectedLocale);

  const [documentToEdit, setDocumentToEdit] = useState<Document | null>(null);
  const [references, setReferences] = useState<DocumentReference[]>([]);
  const [schema, setSchema] = useState<Schema | null>(null);
  const schema_id = documentToEdit?.schema_id || schemaId || Number(params.schema);
  const doc_id = documentIdProp || Number(documentId) || documentToEdit?.id || undefined;
  const isCreateMode = !documentIdProp && (!documentId || !!schemaId) && !doc_id;

  const [title, setTitle] = useState<string>("");
  if (title) document.title = title;
  const [initRelations, setInitRelations] = useState<boolean>(false);

  const handleSetTitle = useCallback(
    (doc: Document | null, schema?: Schema | null) => {
      let title = doc?.internal_title;
      if (!title || typeof title !== "string") {
        title = getLocalizedString(doc?.title) || "";
      }
      if (title) {
        setTitle(title);
      }
    },
    [getLocalizedString]
  );

  const schemas = useMemo<SchemaObject>(
    () => ({
      jsonSchema: parseJson(schema?.json_schema),
      uiSchema: parseJson(schema?.ui_schema),
      preview_url: schema?.preview_url,
      schema_id: schema?.id,
      schemaName: schema?.name,
    }),
    [schema]
  );

  useEffect(() => {
    if (!isCreateMode && !!doc_id) {
      if (!isLoading) {
        setLoading(true);
      }

      Api.getOneDocument(doc_id)
        .fetchDirect(null)
        .then((doc) => {
          setDocumentToEdit(doc);
          const schemaId = Number(doc?.schema_id) || schema_id;
          if (schemaId) {
            Api.getOneSchema(schemaId)
              .fetchDirect(null)
              .then((_schema) => {
                setSchema(_schema);
                handleSetTitle(doc, _schema);
              });
          }
        })
        .finally(() => setLoading(false));
      if (!editorState.documentRelations) {
        Api.getRelationsOfDocument(doc_id)
          .fetchDirect([])
          .then((rel) => {
            if (rel && Array.isArray(rel)) {
              setDocumentRelations(rel);
              addDocumentIds(rel.map((r) => r.document_id));
            }
          })
          .finally(() => setInitRelations(true));
      }
    } else if (schema_id) {
      if (!isLoading) {
        setLoading(true);
      }

      const getSchemas = Api.getOneSchema(schema_id);
      getSchemas
        .fetchDirect(null)
        .then((_schema) => setSchema(_schema))
        .finally(() => setInitRelations(true))
        .finally(() => setLoading(false));
    } else if (!!ownerSlug && !!instanceSlug) navigate(`/${ownerSlug}/${instanceSlug}`);
  }, [doc_id]);

  const fetchDocumentRelations = useDebounce<number[]>(200, (ids?: number[]) => {
    if (ids?.length) {
      const newIds = ids.filter((id) => !editorState.documentRelations?.some((rel) => rel.document_id === id));
      if (newIds.length) {
        const getRelations = Api.getDocumentsAsRelation({ d: newIds });
        getRelations
          .fetchDirect(null)
          .then((rels) => {
            if (rels) {
              addDocumentRelations(rels);
            }
          })
          .finally(getRelations.abort);
      }
    }
  });

  useEffect(() => {
    if (initRelations) {
      fetchDocumentRelations(editorState.documentIds || undefined);
    }
  }, [editorState.documentIds, fetchDocumentRelations, initRelations]);

  const handleSaveDocument = useCallback(
    async (
      modifiedContent: {
        description: string | LocalizedString;
        status: DocumentStatus;
        metadata: { location: Location };
        references: any;
        content: any;
      },
      selectedDocumentStatus: DocumentStatus
    ) => {
      let savedDocument: Document | null;

      if (isCreateMode) {
        savedDocument = await Api.createDocument({
          description: getLocalizedString(modifiedContent.description) || "",
          status: selectedDocumentStatus,
          schema_id,
          content: modifiedContent,
          location: modifiedContent?.metadata?.location || null,
          application_instance_id: applicationInstanceId!,
          references,
        }).fetchDirect(null);

        if (!modalEditor) {
          navigate(`/${ownerSlug}/${instanceSlug}/${savedDocument?.id}`, { replace: true });
        }
      } else {
        savedDocument = await Api.updateDocument({
          ...documentToEdit!,
          content: modifiedContent,
          references,
          location: modifiedContent?.metadata?.location || null,
          status: selectedDocumentStatus,
        }).fetchDirect(null);
      }
      setReferences(savedDocument?.references || []);

      if (savedDocument?.content) {
        success("generic.saved");
        handleSetTitle(savedDocument, schema);
        setDocumentToEdit(savedDocument);
        onSave?.(savedDocument);
        return savedDocument.content;
      } else {
        error("generic.saveFailed");
        return modifiedContent;
      }
    },
    [
      applicationInstanceId,
      documentToEdit,
      error,
      getLocalizedString,
      handleSetTitle,
      instanceSlug,
      isCreateMode,
      modalEditor,
      navigate,
      onSave,
      ownerSlug,
      references,
      schema,
      schema_id,
      success,
    ]
  );

  const setReferencesMemoized = useCallback(
    (val: any) => {
      if (JSON.stringify(val) !== JSON.stringify(references)) {
        setReferences(val);
      }
    },
    [references, setReferences]
  );

  const setSelectedLocaleMemoized = useCallback(
    (val: SetStateAction<string>) => {
      if (val !== selectedLocale) {
        setSelectedLocale(val);
      }
    },
    [selectedLocale]
  );

  if (isLoading) {
    return <Loader loadingText="views.cms.loadingContent" />;
  }

  // The target document could be shared (content_sharing) with the current application instance.
  // If so, prevent editing. API will scream if we try to save it anyway.
  if (documentToEdit?.application_instance_id && documentToEdit?.application_instance_id !== applicationInstanceId) {
    return (
      <Box
        sx={{
          textAlign: "center",
          mx: "auto",
          mt: "20px",
        }}
      >
        <Typography variant={"h6"}>{t("components.EditDocumentLoader.noAccess")}</Typography>
      </Box>
    );
  }

  return (
    <>
      <BreadcrumbNode label={title} absolutePath={""} />
      <EditDocumentView
        selectedLocale={selectedLocale}
        setSelectedLocale={setSelectedLocaleMemoized}
        setReferences={setReferencesMemoized}
        disablePreview={disablePreview}
        schemas={schemas}
        documentToEdit={documentToEdit}
        handleSaveDocument={handleSaveDocument}
        modalEditor={modalEditor}
        isDirty={isDirty}
        setIsDirty={setIsDirty}
      />
    </>
  );
};

export default EditDocumentLoader;

import styled from "@emotion/styled";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { mergeObjects } from "@rjsf/utils";
import { FormContainer } from "./FormContainer";
import Styles from "@/assets/styles/Styles";
import MediaSelector from "@/components/MediaSelector/MediaSelector";
import PersistentDrawer from "@/components/PersistentDrawer";
import type Document from "@/declarations/models/Document";
import DocumentStatus from "@/declarations/models/DocumentStatus";
import KioForm from "@/framework/KioForm/KioForm";
import Layout from "@/framework/Layout";
import { usePrompt } from "@/hooks/useUNSAFE_Prompt";
import Settings from "@/Settings";
import { useStore } from "@/Store";
import { deepCompareFormData, findDeepPropertiesWithContent, objectFromPath } from "@/utils/obj";
import type { EditDocumentLoaderProps, SchemaObject } from "@/views/cms/EditorView/EditDocumentLoader";
import EditorHeader from "@/views/cms/EditorView/EditorHeader";
import EditorPreview from "@/views/cms/EditorView/EditorPreview/EditorPreview";
import type { BlockClipboard, BlockClipboardContextValue } from "@/BlockClipboardContextProvider";
import { BlockClipboardContext } from "@/BlockClipboardContextProvider";

interface EditDocumentViewProps extends EditDocumentLoaderProps {
  schemas: SchemaObject;
  documentToEdit?: Document | null;
  handleSaveDocument: any;
  selectedLocale: any;
  setSelectedLocale: any;
  setReferences: any;
}

const ErrorText = styled("div")`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: crimson;
`;

export const EditDocumentView: FC<EditDocumentViewProps> = ({
  disablePreview,
  schemas,
  documentToEdit,
  handleSaveDocument,
  selectedLocale,
  setSelectedLocale,
  setReferences,
  modalEditor,
  isDirty,
  setIsDirty,
}) => {
  const { state } = useStore();
  const availableLocales = (state.cmsContextInstance?.available_locales || []).map((al) => ({
    language_code: al.language_code!,
    id: al.id!,
  }));
  const { t } = useTranslation("common");

  const [previewOpen, setPreviewOpen] = useState<boolean>(true);
  const [mediaSelectorOpen, setMediaSelectorOpen] = useState<boolean>(false);
  const [modifiedContent, setModifiedContent] = useState<any>(documentToEdit?.content || {});
  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState<DocumentStatus>(
    documentToEdit?.status || DocumentStatus.DRAFT
  );
  const canPublishUnlisted: boolean = !!schemas.uiSchema?.["ui:options"]?.["canPublishUnlisted"];

  usePrompt(t("generic.unsavedChangesMessage"), ({ currentLocation, nextLocation }) => {
    const isPathChanged = currentLocation.pathname !== nextLocation.pathname;
    return isPathChanged ? isDirty : false;
  });

  useEffect(() => documentToEdit?.content && setModifiedContent(documentToEdit.content), [documentToEdit]);

  const handleHeaderSubmitClick = (selectedOption?: string) => {
    switch (selectedOption) {
      case "generic.save_draft":
        handleSaveDocument(modifiedContent, DocumentStatus.DRAFT).then((res: any) => setModifiedContent(res));
        setSelectedDocumentStatus(DocumentStatus.DRAFT);
        break;
      case "generic.save_published":
        handleSaveDocument(modifiedContent, DocumentStatus.PUBLISHED).then((res: any) => setModifiedContent(res));
        setSelectedDocumentStatus(DocumentStatus.PUBLISHED);
        break;
      case "generic.save_published_not_listed":
        handleSaveDocument(modifiedContent, DocumentStatus.PUBLISHED_NOT_LISTED).then((res: any) =>
          setModifiedContent(res)
        );
        setSelectedDocumentStatus(DocumentStatus.PUBLISHED_NOT_LISTED);
        break;
      default:
        handleSaveDocument(modifiedContent, selectedDocumentStatus).then((res: any) => setModifiedContent(res));
    }
    setIsDirty(false);
  };

  const handleOnChange = (formData: any) => {
    if (!formData || !documentToEdit?.content) {
      setIsDirty(true);
      setModifiedContent(formData || {});
      return;
    }
    if (!deepCompareFormData(formData, documentToEdit?.content)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
    setModifiedContent(formData);
  };

  const [currentClipboardItem, setCurrentClipboardItem] = useState<BlockClipboard | null>(null);
  const copy = useCallback<BlockClipboardContextValue["copy"]>((value) => {
    setCurrentClipboardItem({ value });
  }, []);
  const paste = useCallback<BlockClipboardContextValue["paste"]>(
    (pasteIntoArrayId$) => {
      // assumes that pasteIntoArrayId$ is a string in the format of
      // "formId-prefix_field1_field2_field3ThatIsABlockArray"
      if (currentClipboardItem?.value) {
        const targetPath = pasteIntoArrayId$.split("_").slice(1);
        const pasteValue = JSON.parse(JSON.stringify(currentClipboardItem.value));
        const newFormData = mergeObjects(
          JSON.parse(JSON.stringify(modifiedContent)),
          objectFromPath(targetPath, [pasteValue]),
          true
        );
        handleOnChange(newFormData);
      }
    },
    [currentClipboardItem, modifiedContent]
  );
  const clipboardContextValue: BlockClipboardContextValue = useMemo(
    () => ({
      currentClipboard: currentClipboardItem,
      copy,
      paste,
    }),
    [currentClipboardItem, copy, paste]
  );

  /**
   * @description Returns an object where keys are available locale ids and values are true if a locale has content.
   * Used to update language selector checkboxes.
   * Runs once for each rerender. Will work as long as modifiedContent is set by useState.
   * Avoid useMemo as it will cause extra rerenders.
   * @returns {object} - object where keys are available locale ids and values are true if a locale has content
   *
   **/
  const getAvailableLocalesWithContent = () => {
    const _availableLocalesWithContent = findDeepPropertiesWithContent(
      modifiedContent,
      availableLocales.map((al) => al.language_code)
    );
    const availableLocaleIdsWithContent: { [key: number]: boolean } = {};
    availableLocales.forEach((al) => {
      availableLocaleIdsWithContent[al.id!] = _availableLocalesWithContent[al.language_code];
    });

    return availableLocaleIdsWithContent;
  };
  const availableLocalesWithContent = getAvailableLocalesWithContent();

  return (
    <Layout
      header={
        <EditorHeader
          disablePreview={disablePreview}
          previewOpen={!disablePreview && previewOpen}
          onPreviewOpenChanged={setPreviewOpen}
          onSubmit={handleHeaderSubmitClick}
          formIsDirty={isDirty}
          currentStatus={selectedDocumentStatus}
          lastModified={documentToEdit?.updated_at}
          lastModifiedBy={documentToEdit?.updated_by}
          setSelectedLocale={setSelectedLocale}
          selectedLocale={selectedLocale}
          canPublishUnlisted={canPublishUnlisted}
          modalEditor={modalEditor}
          availableLocalesWithContent={availableLocalesWithContent}
        />
      }
    >
      {!schemas.schema_id && !documentToEdit ? (
        <ErrorText>{t("views.admin.schemaPreview.missingSchema")}</ErrorText>
      ) : (
        <MediaSelector
          open={mediaSelectorOpen}
          setOpen={setMediaSelectorOpen}
          width={modalEditor ? "calc(50vw - 16px)" : undefined}
        >
          {modalEditor ? (
            <BlockClipboardContext.Provider value={clipboardContextValue}>
              <KioForm
                formData={modifiedContent}
                selectedLocale={selectedLocale || Settings.DEFAULT_LOCALE}
                onChange={handleOnChange}
                onSubmit={() => {}} // submit from header
                onReferencesChange={setReferences}
                schema={schemas.jsonSchema}
                uiSchema={schemas.uiSchema}
              >
                <button type="submit" hidden={true} />
              </KioForm>
            </BlockClipboardContext.Provider>
          ) : (
            <PersistentDrawer
              open={!disablePreview && previewOpen && !mediaSelectorOpen}
              position="right"
              width={Styles.Dimensions.EDITOR_PREVIEW_WIDTH}
              drawerContent={
                <EditorPreview
                  schemas={schemas}
                  formData={modifiedContent}
                  selectedLocale={selectedLocale}
                  availableLocales={state.cmsContextInstance!.available_locales}
                />
              }
            >
              <BlockClipboardContext.Provider value={clipboardContextValue}>
                <FormContainer>
                  <KioForm
                    formData={modifiedContent}
                    selectedLocale={selectedLocale || Settings.DEFAULT_LOCALE}
                    onChange={handleOnChange}
                    onSubmit={() => {}} // submit from header
                    onReferencesChange={setReferences}
                    schema={schemas.jsonSchema}
                    uiSchema={schemas.uiSchema}
                  >
                    <button type="submit" hidden={true} />
                  </KioForm>
                </FormContainer>
              </BlockClipboardContext.Provider>
            </PersistentDrawer>
          )}
        </MediaSelector>
      )}
    </Layout>
  );
};

export default EditDocumentView;

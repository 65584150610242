import { type FC } from "react";
import { TestModal } from "./TestModal";
import { EditMediaViewModal } from "./EditMediaViewModal";
import { EditDocumentLoaderModal } from "./EditDocumentLoaderModal";
import { DocumentListModalContentModal } from "./DocumentListModalContent";

export const ModalManager: FC = () => (
  <>
    <TestModal />
    <EditMediaViewModal />
    <EditDocumentLoaderModal />
    <DocumentListModalContentModal />
  </>
);

import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { ModalManagerState, ModalManagerStore } from "./types";

export const defaultState: ModalManagerState = {
  testModal: { modals: {}, isOpen: false, currentModalId: "" },
  editMediaView: { modals: {}, isOpen: false, currentModalId: "" },
  documentListModalContent: { modals: {}, isOpen: false, currentModalId: "" },
  editDocumentLoader: { modals: {}, isOpen: false, currentModalId: "" },
};

export const useModalManager = create<ModalManagerStore>()(
  devtools(
    immer((set) => ({
      ...defaultState,

      open: (name, modalId, props, meta) =>
        set(
          (state) => {
            const currentModal = state[name].modals[modalId];

            state[name].modals[modalId] = {
              key: currentModal ? currentModal.key + 1 : 0,
              props: props as any,
              meta,
            };

            state[name].isOpen = true;
            state[name].currentModalId = modalId;
          },
          false,
          "open"
        ),

      close: (name, modalId) =>
        set(
          (state) => {
            state[name].modals[modalId] = undefined;
            state[name].isOpen = false;
          },
          false,
          "close"
        ),

      setProps: (name, modalId, props) =>
        set(
          (state) => {
            const currentModal = state[name].modals[modalId];

            if (currentModal) {
              currentModal.props = props;
            }
          },
          false,
          "setProps"
        ),
    })),
    { name: "ModalManager" }
  )
);

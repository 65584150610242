import type { RJSFSchema } from "@rjsf/utils";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DocumentSelector from "@/components/DocumentSelector/DocumentSelector";
import type DocumentReference from "@/declarations/models/DocumentReference";
import { useEditorStore } from "@/EditorContextProvider";
import type { RelationFieldOptions } from "@/framework/KioForm/fields/DocumentRelationField";
import ArrayRelationFieldItem from "@/framework/KioForm/fields/documentRelationFieldVariants/ArrayRelationFieldItem";
import type { KioFieldProps } from "@/framework/KioForm/KioFieldProps";
import type { KioFormContext } from "@/framework/KioForm/KioForm";
import ArrayFieldTemplate from "@/framework/KioForm/templates/array/ArrayFieldTemplate";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { getSchemaTitle } from "@/utils/schema";

const ArrayRelationField: FC<KioFieldProps> = ({
  schema,
  idSchema,
  uiSchema,
  formContext,
  formData,
  onChange,
  registry,
  errorSchema,
}) => {
  const { state: editorState, addDocumentIds } = useEditorStore();
  const { success } = useMessenger();
  const mutableData = useRef<DocumentReference[]>(Array.isArray(formData) ? formData : []);

  const itemSchema = Array.isArray(schema.items) ? (schema.items[0] as RJSFSchema) : (schema.items as RJSFSchema);
  const uiOptions = uiSchema?.["ui:options"] as any as RelationFieldOptions;
  const [items, setItems] = useState<any[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // used to force onChange when adding item
  const { schemaUtils } = registry;

  useEffect(
    () => () => {
      if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    },
    []
  );

  useEffect(() => {
    if (Array.isArray(formData)) addDocumentIds(formData.map((item: DocumentReference) => item.to_document_id));
    else if (formData && Object.prototype.hasOwnProperty.call(formData, "to_document_id"))
      addDocumentIds([formData.to_document_id]);
  }, [addDocumentIds, formData]);

  const onChangeForIndex = useCallback(
    (index: number) => (val: DocumentReference) => {
      const existingFormData = [...mutableData.current];
      const newFormData = [
        ...existingFormData.slice(0, index),
        val,
        ...existingFormData.slice(index + 1, existingFormData.length),
      ];
      onChange(newFormData);
      mutableData.current = newFormData;
    },
    [onChange]
  );

  const onDropIndex = useCallback(
    (index: number) => () => {
      const newFormData = [...mutableData.current.slice(0, index), ...mutableData.current.slice(index + 1)];
      onChange(newFormData);
      mutableData.current = newFormData;
    },
    [onChange]
  );

  const onReorder = useCallback(
    (index: number, newIndex: number) => () => {
      const newFormData = mutableData.current.slice();
      newFormData.splice(index, 1);
      newFormData.splice(newIndex, 0, mutableData.current[index]);
      onChange(newFormData);
      mutableData.current = newFormData;
    },
    [onChange]
  );

  const mapRelationToArrayTemplateItem = useCallback(
    (item: any, i: number, length: number) => {
      const relation = (editorState.documentRelations || []).find((rel) => rel.document_id === item.to_document_id);
      if (relation) {
        const itemIdSchema = schemaUtils.toIdSchema(itemSchema, `${idSchema.$id}_${i}`, relation);
        return {
          className: "",
          disabled: false,
          hasMoveDown: i < length - 1,
          hasMoveUp: i > 0,
          hasRemove: true,
          hasToolbar: true,
          isFromSharedInstance:
            !!relation.application_instance_id &&
            !!formContext.applicationInstanceId &&
            relation.application_instance_id !== formContext.applicationInstanceId,
          index: i,
          onDropIndexClick: onDropIndex,
          onReorderClick: onReorder,
          readonly: false,
          key: `${relation.document_id}`,
          description: relation.status,
          children: (
            <ArrayRelationFieldItem
              key={relation.document_id}
              documentRelation={relation}
              schema={itemSchema}
              uiSchema={uiSchema}
              formData={formData?.[i]}
              idSchema={itemIdSchema}
              errorSchema={errorSchema?.[i]}
              registry={registry}
              formContext={formContext}
              onChange={onChangeForIndex(i)}
              uiOptions={uiOptions}
              autofocus={false}
              disabled={false}
              required={false}
              name={`item-${i}`}
              onBlur={() => {}}
              onFocus={() => {}}
              readonly={false}
            />
          ),
        };
      }
    },
    [
      editorState.documentRelations,
      errorSchema,
      formContext,
      formData,
      idSchema.$id,
      itemSchema,
      onChangeForIndex,
      onDropIndex,
      onReorder,
      registry,
      schemaUtils,
      uiOptions,
      uiSchema,
    ]
  );

  const addDocument = useCallback(
    (documentId: number) => {
      if (!!documentId && !mutableData.current.some((ref: DocumentReference) => ref.to_document_id === documentId)) {
        const newFormData = [...mutableData.current, { to_document_id: documentId }];
        timeoutRef.current = setTimeout(() => {
          onChange(newFormData);
          timeoutRef.current = null;
        });
        mutableData.current = newFormData;
        success("kioForm.fields.DocumentRelationField.documentAddedSuccess", 2000);
      }
    },
    [onChange, success]
  );

  useEffect(() => {
    if (Array.isArray(formData)) {
      const newItems = formData
        .map((item, i) => mapRelationToArrayTemplateItem(item, i, formData.length))
        .filter((item) => !!item);

      if (newItems) setItems(newItems);
    }
  }, [formData, editorState.documentRelations, mapRelationToArrayTemplateItem]);

  const selectedItems = useMemo(() => items.map((item) => item.key), [items]);

  return (
    <>
      <ArrayFieldTemplate
        canAdd={false}
        className={""}
        disabled={false}
        idSchema={idSchema}
        items={items}
        onAddClick={() => {}}
        readonly={false}
        required={false}
        schema={schema}
        uiSchema={uiSchema}
        title={getSchemaTitle(formContext, schema, uiSchema, formData)}
        formContext={formContext}
        formData={formData}
        registry={registry}
      />

      {(!uiOptions?.singleRelation || items.length === 0) && (
        <DocumentSelector
          multiple
          selectedItems={selectedItems}
          onSelect={addDocument}
          selectedLanguage={(formContext as KioFormContext).selectedLocale}
          uiOptions={uiOptions}
        />
      )}
    </>
  );
};

export default ArrayRelationField;

import { Fullscreen, Info, OpenInNew } from "@mui/icons-material";
import { Box, ClickAwayListener, Popper, styled, Tooltip, Typography } from "@mui/material";
import type { UiSchema, RJSFSchema } from "@rjsf/utils";
// eslint-disable-next-line import/no-duplicates
import { parseISO } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import { nb } from "date-fns/locale";
import i18next from "i18next";
import type { FC, MouseEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Capture3DModelAndUploadThumbnail } from "./Capture3DModelAndUploadThumbnail";
import { FormContainer } from "./FormContainer";
import Styles from "@/assets/styles/Styles";
import BreadcrumbNode from "@/components/BreadcrumbNode";
import { FullscreenPreview } from "@/components/FullscreenPreview";
import MediaPreview from "@/components/MediaPreview/MediaPreview";
import MediaSelector from "@/components/MediaSelector/MediaSelector";
import PersistentDrawer from "@/components/PersistentDrawer";
import type { MediaData } from "@/declarations/models/Media";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import KioForm from "@/framework/KioForm/KioForm";
import Layout from "@/framework/Layout";
import Loader from "@/framework/Loader";
import { useMessenger } from "@/framework/Messenger/Messenger";
import { useAsyncSafeState } from "@/hooks/useAsyncSafeState";
import { useLoadingState } from "@/hooks/useLoadingState";
import { usePrompt } from "@/hooks/useUNSAFE_Prompt";
import Api from "@/services/Api";
import Settings from "@/Settings";
import { dateFormater } from "@/utils/dates";
import { checkIsDirty, getLanguageCode, resolvePotentiallyLocalizedString } from "@/utils/obj";
import EditorHeader from "@/views/cms/EditorView/EditorHeader";
import { PreviewButton } from "@/views/cms/EditorView/EditorPreview/ViewTypePreview";
import { audioJsonSchema, audioUiSchema } from "@/views/cms/EditorView/mediaSchemas/audioSchema";
import { baseJsonSchema, baseUiSchema } from "@/views/cms/EditorView/mediaSchemas/baseMediaSchema";
import { imageJsonSchema, imageUiSchema } from "@/views/cms/EditorView/mediaSchemas/imageSchema";
import { videoJsonSchema, videoUiSchema } from "@/views/cms/EditorView/mediaSchemas/videoSchema";
import type { Credit } from "@/declarations/models/Credit";
import { DMJsonSchema, DMUiSchema } from "@/views/cms/EditorView/mediaSchemas/DMSchema";
import { useStore } from "@/Store";
import { model3dJsonSchema, model3dUiSchema } from "@/views/cms/EditorView/mediaSchemas/model3dSchema";
import CollapseBar from "@/framework/KioForm/common/CollapseBar";
import { Model3dPreview } from "@/components/MediaPreview/Model3dPreview";

const PopperContentRow = styled("div")({
  display: "inline-flex",

  flexDirection: "row",
  "& p": {
    margin: "0.5em 0",
  },
});

const MediaPreviewWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "& > div": {
    margin: " 0 auto",
  },
  "& video": {
    display: "block",
    margin: "0 auto",
  },
});
const Toolbar = styled("menu")({
  position: "fixed",
  left: "50vw",
  right: 0,
  bottom: 0,
  margin: 0,
  padding: 0,
  display: "flex",
});

const PopperContentRowKey = styled("p")`
  color: ${Styles.Colors.EDITOR_TABS_BORDER_COLOR};
  text-align: right;
  min-width: 13em;
  padding-right: 0.6em;
`;

const PopperContentRowValue = styled("p")`
  color: ${Styles.Colors.WHITE};
  text-align: left;
  min-width: 13em;
`;

const PopperContent = styled("div")`
  padding: 1em 1.5em;
  background: ${Styles.Colors.PREVIEW_BACKGROUND_COLOR};
  color: ${Styles.Colors.WHITE};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export interface EditMediaProps {
  disablePreview?: boolean;
  modalEditor?: boolean;
  mediaIdProp?: number;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}

const EditMediaView: FC<EditMediaProps> = ({
  disablePreview = false,
  modalEditor = false,
  mediaIdProp,
  isDirty,
  setIsDirty,
}) => {
  const { t } = useTranslation("common");
  const { isLoading, startLoading, stopLoading } = useLoadingState();
  const { success, error } = useMessenger();

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const toggleMetaInfoViewMode = useRef<HTMLButtonElement>(null);
  const { state } = useStore();
  const applicationInstanceId = state.cmsContextInstance?.id;

  const mediaId = Number(useParams<{ mediaId?: string }>().mediaId);

  const [previewOpen, setPreviewOpen] = useAsyncSafeState<boolean>(true);
  const [mediaToEdit, setMediaToEdit] = useAsyncSafeState<Media | null>(null);
  const [modifiedMetadata, setModifiedMetadata] = useAsyncSafeState<MediaData | null>(null);
  const [selectedLocale, setSelectedLocale] = useState<string>(getLanguageCode(i18next.language));
  const title = mediaToEdit?.name || resolvePotentiallyLocalizedString(selectedLocale)(mediaToEdit?.title) || "";

  if (title) document.title = title;

  const [editThumbnailIsOpen, setEditThumbnailIsOpen] = useState<boolean>(false);
  const [processThumbnailBeforeSubmitHandler, setProcessThumbnailBeforeSubmitHandler] = useState<
    ((m: Media) => Promise<Media>) | null
  >(null);

  const [openFullScreen, setOpenFullScreen] = useState<boolean>(false);
  const [mediaSelectorOpen, setMediaSelectorOpen] = useState<boolean>(false);

  const [jsonSchema, setJsonSchema] = useState<RJSFSchema>({});
  const [uiSchema, setUiSchema] = useState<UiSchema>({});

  useEffect(() => {
    switch (mediaToEdit?.media_type) {
      case MediaType.IMAGE:
        setJsonSchema(imageJsonSchema);
        setUiSchema(imageUiSchema);
        break;
      case MediaType.VIDEO:
        setJsonSchema(videoJsonSchema);
        setUiSchema(videoUiSchema);
        break;
      case MediaType.AUDIO:
        setJsonSchema(audioJsonSchema);
        setUiSchema(audioUiSchema);
        break;
      case MediaType.DM:
        setJsonSchema(DMJsonSchema);
        setUiSchema(DMUiSchema);
        break;
      case MediaType.MODEL_3D:
        setJsonSchema(model3dJsonSchema);
        setUiSchema(model3dUiSchema);
        break;
      default:
        setJsonSchema(baseJsonSchema);
        setUiSchema(baseUiSchema);
    }
  }, [mediaToEdit]);

  function unwrapMetaData(metaData: MediaData | null) {
    const unwrapped: any = {
      ...metaData?.general,
      ...metaData?.metaData,
      ...metaData?.captions,
      ...metaData?.settings,
    };

    const ADDITIONAL_METADATA_KEYS = [
      "albumTitle",
      "catalogNumber",
      "episode",
      "locationShot",
      "locationShot",
      "locationShown",
      "season",
      "series",
    ];

    unwrapped["additional_metadata"] = {};
    type objectHelperType = { [key: string]: any }; // in case ts believes object is not indexable by string

    // Remove root level keys and move non-undefined keys to additional_metadata (isDirty fix)
    ADDITIONAL_METADATA_KEYS.forEach((elemKey) => {
      if ((metaData?.metaData as objectHelperType)?.[elemKey] !== undefined) {
        unwrapped.additional_metadata[elemKey] = (metaData?.metaData as objectHelperType)[elemKey];
      }
      delete unwrapped[elemKey];
    });

    unwrapped["credits"] = metaData?.credits;

    return unwrapped;
  }

  useEffect(() => {
    setIsDirty(
      checkIsDirty(mediaToEdit, {
        ...mediaToEdit,
        ...unwrapMetaData(modifiedMetadata),
      }) || !!processThumbnailBeforeSubmitHandler
    );
  }, [mediaToEdit, modifiedMetadata, processThumbnailBeforeSubmitHandler]);

  usePrompt(t("generic.unsavedChangesMessage"), isDirty);

  useEffect(() => {
    const beforeUnloadHandler = (event: Event) => {
      if (isDirty) {
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
        event.preventDefault();
        event.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [isDirty]);

  const handleSubmit = async (data: MediaData) => {
    if (mediaToEdit && modifiedMetadata) {
      const updatedMedia = {
        ...mediaToEdit,
        ...unwrapMetaData(data),
      };

      // add order property to credits (one indexed).
      updatedMedia.credits?.forEach((credit: Credit, idx: number) => {
        credit.order = idx + 1;
      });

      const saved = await Api.updateMedia(
        processThumbnailBeforeSubmitHandler ? await processThumbnailBeforeSubmitHandler(updatedMedia) : updatedMedia
      ).fetchDirect(null);

      if (saved) {
        setMediaToEdit(saved);
        success("generic.saved");
        setProcessThumbnailBeforeSubmitHandler(null);
      } else {
        error("generic.saveFailed");
      }
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "media metadata" : undefined;

  const mediaMetaDataToBeShown = {
    mediaType: mediaToEdit?.media_type,
    filename: mediaToEdit?.filename,
    uploaded: mediaToEdit?.created_at,
    uploadedBy: mediaToEdit?.created_by,
  };

  useEffect(() => {
    startLoading();
    if (!mediaIdProp && (!mediaId || mediaId <= 0)) {
      setMediaToEdit(null);
      setModifiedMetadata(null);
      return;
    }
    const getMedia = Api.getOneMedia(mediaIdProp || mediaId);
    getMedia
      .fetchDirect(null)
      .then((media) => {
        setMediaToEdit(media);
      })
      .finally(stopLoading);
    return getMedia.abort;
  }, [mediaId, mediaIdProp]);

  useEffect(() => {
    setModifiedMetadata({
      general: {
        name: mediaToEdit?.name ?? "",
        title: mediaToEdit?.title ?? undefined,
        description: mediaToEdit?.description ?? undefined,
        alt_text: mediaToEdit?.alt_text ?? undefined,
      },
      metaData: {
        license: mediaToEdit?.license ?? "",
        tags: mediaToEdit?.tags ?? undefined,
        category: mediaToEdit?.category ?? "",
        year: mediaToEdit?.year ?? undefined,
        albumTitle: mediaToEdit?.additional_metadata?.albumTitle ?? undefined,
        catalogNumber: mediaToEdit?.additional_metadata?.catalogNumber ?? undefined,
        episode: mediaToEdit?.additional_metadata?.episode ?? undefined,
        locationShot: mediaToEdit?.additional_metadata?.locationShot ?? undefined,
        locationShown: mediaToEdit?.additional_metadata?.locationShown ?? undefined,
        season: mediaToEdit?.additional_metadata?.season ?? undefined,
        series: mediaToEdit?.additional_metadata?.series ?? undefined,
      },
      captions: {
        /* @RJSF5-ERR */
        closed_captions: mediaToEdit?.closed_captions ?? undefined,
      },
      settings: {
        volume: mediaToEdit?.volume ?? undefined,
        thumbnail_override: mediaToEdit?.thumbnail_override ?? undefined,
      },
      credits: mediaToEdit?.credits ?? [],
    });
  }, [setModifiedMetadata, mediaToEdit]);

  if (isLoading) return <Loader loadingText="views.cms.loadingContent" />;

  if (!mediaToEdit || !modifiedMetadata) {
    return <Loader />;
  }

  if (mediaToEdit.application_instance_id && mediaToEdit.application_instance_id !== applicationInstanceId) {
    return (
      <Box
        sx={{
          textAlign: "center",
          mx: "auto",
          mt: "20px",
        }}
      >
        <Typography variant={"h6"}>{t("components.EditMediaView.noAccess")}</Typography>
      </Box>
    );
  }

  return (
    <>
      <BreadcrumbNode label={title} absolutePath={""} />
      <Layout
        header={
          <EditorHeader
            formIsDirty={isDirty}
            selectedLocale={selectedLocale}
            onSubmit={() => submitButtonRef.current?.click()}
            setSelectedLocale={setSelectedLocale}
            lastModified={mediaToEdit?.updated_at}
            lastModifiedBy={mediaToEdit?.updated_by}
            onPreviewOpenChanged={setPreviewOpen}
            disablePreview={disablePreview}
            modalEditor={modalEditor}
            previewOpen={!disablePreview && previewOpen}
          />
        }
      >
        <MediaSelector
          open={mediaSelectorOpen}
          setOpen={setMediaSelectorOpen}
          width={modalEditor ? "calc(50vw - 16px)" : ""}
        >
          {modalEditor ? (
            <KioForm
              formData={modifiedMetadata}
              onChange={setModifiedMetadata}
              selectedLocale={selectedLocale || Settings.DEFAULT_LOCALE}
              schema={jsonSchema}
              uiSchema={uiSchema}
              onSubmit={handleSubmit}
            >
              <button ref={submitButtonRef} type="submit" hidden={true} />

              {mediaToEdit.media_type === MediaType.MODEL_3D && (
                <CollapseBar
                  title={t("generic.thumbnail")}
                  expanded={editThumbnailIsOpen}
                  setExpanded={setEditThumbnailIsOpen}
                  sx={{
                    backgroundColor: Styles.Colors.WHITE,
                  }}
                >
                  <Capture3DModelAndUploadThumbnail
                    media={mediaToEdit}
                    mediaData={modifiedMetadata}
                    registerBeforeSubmitHandler={(callback) => setProcessThumbnailBeforeSubmitHandler(() => callback)}
                    modelViewerId={`edit-media-modal-model-viewer-${mediaToEdit.id}`}
                  />
                  <Box
                    sx={{
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <Typography variant="caption">{t("generic.preview")}</Typography>
                    <Box
                      sx={{
                        position: "relative",
                        height: "140px",
                        width: "140px",
                        border: `1px dotted ${Styles.Colors.PREVIEW_BORDER_COLOR}`,
                      }}
                    >
                      <Model3dPreview media={mediaToEdit} id={`edit-media-modal-model-viewer-${mediaToEdit.id}`} />
                    </Box>
                  </Box>
                </CollapseBar>
              )}
            </KioForm>
          ) : (
            <PersistentDrawer
              open={!disablePreview && previewOpen}
              position="right"
              width={Styles.Dimensions.EDITOR_PREVIEW_WIDTH}
              drawerContent={
                mediaToEdit ? (
                  <>
                    <MediaPreviewWrapper>
                      <MediaPreview media={mediaToEdit} height={"600px"} selectedLocale={selectedLocale} />
                    </MediaPreviewWrapper>

                    <Toolbar>
                      {mediaToEdit.media_type === MediaType.IMAGE && (
                        <>
                          <Tooltip title={t("views.cms.preview.showFullSize")}>
                            <PreviewButton aria-haspopup="true" onClick={() => setOpenFullScreen(!openFullScreen)}>
                              <Fullscreen />
                            </PreviewButton>
                          </Tooltip>
                          <FullscreenPreview open={openFullScreen} setOpen={setOpenFullScreen} media={mediaToEdit} />
                        </>
                      )}
                      <div>
                        <Tooltip title={t("views.cms.preview.showFileInfo")}>
                          <PreviewButton
                            ref={toggleMetaInfoViewMode}
                            aria-controls="show-meta-data"
                            aria-haspopup="true"
                            onClick={handleClick}
                            aria-describedby={id}
                          >
                            <Info />
                          </PreviewButton>
                        </Tooltip>

                        <Popper id={id} disablePortal={true} open={open} placement={"top-start"} anchorEl={anchorEl}>
                          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                            <PopperContent>
                              {Object.entries(mediaMetaDataToBeShown).map(([key, value]) => (
                                <PopperContentRow key={key}>
                                  <PopperContentRowKey>{t(`mediaData.${key}`)} :</PopperContentRowKey>
                                  <PopperContentRowValue>
                                    {value && key === "uploaded"
                                      ? dateFormater(parseISO(value), "dd MMMM y", { locale: nb })
                                      : value}
                                  </PopperContentRowValue>
                                </PopperContentRow>
                              ))}
                            </PopperContent>
                          </ClickAwayListener>
                        </Popper>
                      </div>
                      <div>
                        <Tooltip title={t("views.cms.preview.openMedia")}>
                          <PreviewButton onClick={() => window.open(mediaToEdit.src)}>
                            <OpenInNew />
                          </PreviewButton>
                        </Tooltip>
                      </div>
                    </Toolbar>
                  </>
                ) : (
                  <></>
                )
              }
            >
              <FormContainer>
                <KioForm
                  formData={modifiedMetadata}
                  onChange={setModifiedMetadata}
                  selectedLocale={selectedLocale || Settings.DEFAULT_LOCALE}
                  schema={jsonSchema}
                  uiSchema={uiSchema}
                  onSubmit={handleSubmit}
                  sx={{
                    gap: 0,
                  }}
                >
                  <>
                    <button ref={submitButtonRef} type="submit" hidden={true} />
                    {mediaToEdit.media_type === MediaType.MODEL_3D && (
                      <CollapseBar
                        title={t("generic.thumbnail")}
                        expanded={editThumbnailIsOpen}
                        setExpanded={setEditThumbnailIsOpen}
                        sx={{
                          backgroundColor: Styles.Colors.WHITE,
                        }}
                      >
                        <Capture3DModelAndUploadThumbnail
                          media={mediaToEdit}
                          mediaData={modifiedMetadata}
                          registerBeforeSubmitHandler={(callback) =>
                            setProcessThumbnailBeforeSubmitHandler(() => callback)
                          }
                        />
                      </CollapseBar>
                    )}
                  </>
                </KioForm>
              </FormContainer>
            </PersistentDrawer>
          )}
        </MediaSelector>
      </Layout>
    </>
  );
};

export default EditMediaView;

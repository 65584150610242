import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useModalManager } from "./store";
import { FullScreenDialog } from "@/components/MediaSelector/MediaTypeTabPanel/FullScreenDialog";
import { ModalWrapper } from "@/components/MediaSelector/MediaTypeTabPanel/ModalWrapper";
import DocumentListModalContent from "@/components/DocumentSelector/DocumentListModalContent";
import { DialogActions } from "@/components/Lexical/ui/Dialog";

export const DocumentListModalContentModal: FC = () => {
  const currentModalId = useModalManager((s) => s.documentListModalContent.currentModalId);
  const modal = useModalManager((s) => s.documentListModalContent.modals[currentModalId]);
  const isOpen = useModalManager((s) => s.documentListModalContent.isOpen);
  const { t } = useTranslation("common");

  return (
    <FullScreenDialog maxWidth={"xl"} fullWidth open={isOpen} onClose={modal?.meta.onClose} scroll={"paper"}>
      <ModalWrapper>{modal?.props && <DocumentListModalContent {...modal.props} />}</ModalWrapper>
      <DialogActions>
        <Button type="button" color="primary" variant="contained" onClick={modal?.meta.onClose}>
          {t("generic.close")}
        </Button>
      </DialogActions>
    </FullScreenDialog>
  );
};

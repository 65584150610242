import { deepEqual } from "fast-equals";
import { usePrevious } from "./usePrevious";

/**
 * Returns boolean, true if the provided object has changed since
 * the last render, false if not.
 */
export const useObjectHasChanged = (obj: {}, isLoggingEnabled = false): boolean => {
  const prev = usePrevious(obj, obj);
  const hasChanged = !deepEqual(obj, prev);

  if (isLoggingEnabled) {
    console.log("useObjectHasChanged:", { current: obj, prev, hasChanged });
  }

  return hasChanged;
};

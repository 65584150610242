import { Add } from "@mui/icons-material";
import { styled, Typography } from "@mui/material";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { DataListProps } from "@/components/DataList/DataList";
import DataList from "@/components/DataList/DataList";
import type { DataListRow, DataListRowInternal } from "@/components/DataList/DataListRow";
import { ViewType } from "@/components/DataList/ListHeader/ViewTypeSelector";
import AddMediaModal from "@/components/MediaSelector/AddMediaModal";
import SearchField from "@/components/SearchField";
import type ApplicationInstance from "@/declarations/models/ApplicationInstance";
import type Media from "@/declarations/models/Media";
import MediaType from "@/declarations/models/MediaType";
import type Owner from "@/declarations/models/Owner";
import { KioSelect } from "@/framework/KioForm/common/KioSelect";
import { useDebounce } from "@/hooks/useDebounce";
import Api from "@/services/Api";
import { useModal } from "@/framework/ModalManager/useModal";
import { useResolvePotentiallyLocalizedString } from "@/hooks/useResolvePotentiallyLocalizedString";

export interface MediaTypeManagerProps {
  mediaTypes: MediaType | MediaType[];
  onSelect: (value: Media | Media[]) => void;
  drawerOpen?: boolean;
  instanceId?: number;
  ownerId?: number;
  selectMultiple?: boolean;
  selectedList?: string[];
  instance?: ApplicationInstance | null;
}

const Container = styled("div")({
  display: "flex",
  flexFlow: "column",
  justifyContent: "flex-start",
  height: "100%",
  width: "100%",
  padding: 0,

  "& .MuiChip-outlinedPrimary": {
    color: "rgba(0, 0, 0, 0.6)",
    border: "1px solid",
  },
});

const SearchContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "40px",
  margin: "16px 16px -56px 16px",
  marginBottom: "-56px",
  zIndex: 2,
  width: "calc(100% - 295px)",
  gap: theme.spacing(2),
  "& .MuiFormControl-root": {
    minWidth: "40px",
  },
  "& .MuiInputBase-root": {
    height: "40px",
  },
}));

const getMedia =
  (
    media_types: MediaType[],
    owner_id: Owner["id"],
    applicationInstance: ApplicationInstance["id"],
    search?: string,
    order_by?: string,
    order_asc?: boolean
  ): DataListProps<Media>["getItems"] =>
  async (page, page_size) =>
    Api.getAllMedia({
      page,
      page_size,
      media_types,
      owner_id,
      application_instance_id: applicationInstance,
      search,
      order_by,
      order_asc,
    }).fetchDirect({
      page,
      page_size,
      count: 0,
      items: [],
      total_count: 0,
    });
const AddButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "white",
  height: "24px",
  paddingLeft: "6px",
  borderRadius: "12px",
  backgroundColor: theme.palette.primary.main,
}));

export const MediaTypeTabPanel: FC<MediaTypeManagerProps> = ({
  mediaTypes,
  onSelect,
  drawerOpen,
  instance,
  ownerId,
  selectMultiple,
  selectedList,
}) => {
  const mediaTypesArr = useMemo(() => (Array.isArray(mediaTypes) ? mediaTypes : [mediaTypes]), [mediaTypes]);

  const { t, i18n } = useTranslation("common");
  const resolveLocalizedString = useResolvePotentiallyLocalizedString(i18n.language);

  const [addContentDialogOpen, setAddContentDialogOpen] = useState<boolean>(false);
  const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState<number>(Date.now());
  const [selectedMediaTypes, setSelectedMediaTypes] = useState<MediaType[]>(mediaTypesArr);
  const [sortBy, setSortBy] = useState<string>("updated_at");
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  const [mediaId, setMediaId] = useState<number>();
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");
  const [selectedInstanceId, setSelectedInstanceId] = useState(instance?.id);
  const [sharedInstances, setSharedInstances] = useState<ApplicationInstance[]>([]);

  useEffect(() => {
    if (instance?.id) {
      Api.getSharedInstances(instance.id, { type: "media" })
        .fetchDirect(null)
        .then((page) => setSharedInstances(page?.items || []));
    }
  }, [instance]);

  const debouncedSetSearchTerms = useDebounce<string>(500, (t) => {
    setSearchTerms(t || "");
    setLastFetchedTimestamp(Date.now());
  });

  useEffect(() => {
    if (searchInput !== searchTerms) debouncedSetSearchTerms(searchInput);
  }, [debouncedSetSearchTerms, searchInput, searchTerms]);

  useEffect(() => {
    if (!drawerOpen && searchInput !== "") setSearchInput("");
  }, [drawerOpen, searchInput]);

  const mapMediaToListItem = (media: Media): DataListRow => ({
    key: String(media.id),
    title: media.name || media.title || "*",
    chipContent: media.media_type ? t(`mediaType.${media.media_type}`) : "",
    imageURL: media.thumbnail_override?.mediaId || media.thumbnail_src,
    updatedAt: media.updated_at,
    updatedBy: media.updated_by,
  });

  const isMedia = Boolean(
    mediaTypesArr.includes(MediaType.IMAGE) ||
      mediaTypesArr.includes(MediaType.AUDIO) ||
      mediaTypesArr.includes(MediaType.VIDEO)
  );

  const modal = useModal(
    "editMediaView",
    {
      mediaIdProp: mediaId,
      modalEditor: true,
      disablePreview: true,
      isDirty,
      setIsDirty,
    },
    useCallback(
      (close: () => void) => ({
        onClose: () => {
          if (!isDirty || window.confirm(t("generic.unsavedChangesMessage"))) close();
        },
      }),
      [isDirty, t]
    )
  );

  const handleEdit = (media: Media) => {
    setMediaId(media.id);
    modal.open();
  };

  const handleMediaChanged = () => {
    setLastFetchedTimestamp(Date.now());
    setAddContentDialogOpen(false);
  };

  const handleOnItemsChanged = (sortProp?: string, sortDirection?: string) => {
    if (sortProp && sortProp !== sortBy) setSortBy(sortProp);
    if (sortDirection) setSortAscending(sortDirection === "asc");
    setLastFetchedTimestamp(Date.now());
  };

  useEffect(() => {
    if (!!mediaTypesArr && mediaTypesArr !== selectedMediaTypes) {
      if (isMedia && selectedMediaTypes.length === 0) {
        setSelectedMediaTypes(mediaTypesArr);
      } else if (!isMedia && selectedMediaTypes.length > 0) {
        setSelectedMediaTypes([]);
      }
      setLastFetchedTimestamp(Date.now());
    }
  }, [isMedia, mediaTypesArr, selectedMediaTypes]);

  const handleChange = (value: any) => {
    setSelectedMediaTypes(value);
    setLastFetchedTimestamp(Date.now());
  };

  const getItems = useMemo(
    () =>
      selectedInstanceId
        ? getMedia(
            isMedia && selectedMediaTypes.length > 0 ? selectedMediaTypes : mediaTypesArr,
            undefined,
            selectedInstanceId,
            searchTerms,
            sortBy,
            sortAscending
          )
        : getMedia(
            isMedia && selectedMediaTypes.length > 0 ? selectedMediaTypes : mediaTypesArr,
            ownerId,
            undefined,
            searchTerms,
            sortBy,
            sortAscending
          ),
    [selectedInstanceId, isMedia, selectedMediaTypes, mediaTypesArr, searchTerms, sortBy, sortAscending, ownerId]
  );

  const addButton = () => (
    <AddButton>
      <Typography variant={"caption"} lineHeight={1}>
        {t("Legg til")}
      </Typography>
      <Add />
    </AddButton>
  );

  const resetPageDeps = useMemo(() => [searchTerms], [searchTerms]);
  const externalDataChanged = useMemo(
    () => ({ lastFetchedTimestamp, selectedInstanceId }),
    [lastFetchedTimestamp, selectedInstanceId]
  );

  return (
    <Container>
      {instance && sharedInstances?.length ? (
        <KioSelect
          sx={{
            my: 2,
          }}
          onChange={setSelectedInstanceId}
          options={[instance, ...sharedInstances].map((i) => ({
            label: resolveLocalizedString(i.owner_name) ?? i.name ?? "",
            value: i.id,
          }))}
          value={selectedInstanceId}
        />
      ) : null}
      <SearchContainer>
        <SearchField value={searchInput} fullWidth onChange={setSearchInput} placeholder={t("search.searchMedia")} />
        {isMedia && mediaTypesArr.length > 1 && (
          <KioSelect
            multiple
            displayEmpty
            options={mediaTypesArr.map((mediaType) => ({
              value: mediaType,
              label: t(`mediaType.${mediaType}`),
            }))}
            value={selectedMediaTypes}
            onChange={handleChange}
            isHeaderVariant
            renderValue={() => {
              const displayMediaTypes = selectedMediaTypes.length > 0 ? selectedMediaTypes : mediaTypesArr;
              return (
                <div>
                  <span>{t("components.MediaSelector.MediaSelectSelectedIndicator")} </span>
                  {displayMediaTypes.map((mediaType, key) => (
                    <span style={{ textTransform: "lowercase" }} key={key}>
                      {(key > 0 ? ", " : "") + t(`mediaType.plural.${mediaType}`)}
                    </span>
                  ))}
                </div>
              );
            }}
          />
        )}
      </SearchContainer>
      <DataList
        mapperFn={mapMediaToListItem}
        getItems={getItems}
        onItemClick={selectMultiple ? undefined : onSelect}
        onEditItem={handleEdit}
        handleOnItemsChanged={handleOnItemsChanged}
        hideUpdated={true}
        altUpdatedAt={true}
        denseOptions
        defaultViewType={ViewType.LIST}
        externalDataChanged={externalDataChanged}
        initialSortOption={2}
        createNewButton={{
          onCreateNew: () => setAddContentDialogOpen((o) => !o),
          buttonLabel:
            ([MediaType.IMAGE, MediaType.VIDEO, MediaType.AUDIO, MediaType.DOCUMENT].includes(mediaTypesArr[0])
              ? t("components.MediaSelector.upload")
              : t("components.MediaSelector.add")) || "",
        }}
        resetPageDeps={resetPageDeps}
        disableSelection={!selectMultiple}
        selectedItems={selectedList}
        customBatchButtons={[
          {
            tooltip: t("components.list.header.addSelected"),
            onClickAll: (items: DataListRowInternal<Media>[]) => onSelect(items.map((i) => i.sourceItem)),
            icon: addButton(),
            displayCondition: () => !!selectMultiple,
          },
        ]}
      />
      <AddMediaModal
        mediaTypes={mediaTypesArr}
        open={addContentDialogOpen}
        onCancel={() => setAddContentDialogOpen(false)}
        onMediaSaved={handleMediaChanged}
        ownerId={ownerId}
        instanceId={instance?.id}
      />
    </Container>
  );
};

export default MediaTypeTabPanel;

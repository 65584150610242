import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useModalManager } from "./store";
import { FullScreenDialog } from "@/components/MediaSelector/MediaTypeTabPanel/FullScreenDialog";
import { ModalWrapper } from "@/components/MediaSelector/MediaTypeTabPanel/ModalWrapper";
import { DialogActions } from "@/components/Lexical/ui/Dialog";

export const TestModal: FC = () => {
  const currentModalId = useModalManager((s) => s.testModal.currentModalId);
  const modal = useModalManager((s) => s.testModal.modals[currentModalId]);
  const isOpen = useModalManager((s) => s.testModal.isOpen);

  const { t } = useTranslation("common");

  return (
    <FullScreenDialog maxWidth={"xs"} fullWidth open={isOpen} scroll={"paper"}>
      <ModalWrapper>
        {modal?.props.content}
        <div>
          <button onClick={modal?.props.testFn}>testFn</button>
        </div>
      </ModalWrapper>
      <DialogActions>
        <Button type="button" color="primary" variant="contained" onClick={modal?.meta.onClose}>
          {t("generic.close")}
        </Button>
      </DialogActions>
    </FullScreenDialog>
  );
};

import type { RJSFSchema, UiSchema } from "@rjsf/utils";
import type { TemplateLayout } from "../TemplateLayout";
import { ArrayFieldLayoutTemplate, ObjectFieldTemplateLayout } from "../TemplateLayout";
import { isObject } from "@/utils/obj";
import { WhiteListedWidgets, WidgetNames } from "@/framework/KioForm/widgets/widgetList";
import { FieldNames, WhiteListedFields } from "@/framework/KioForm/fieldList";

export enum ErrorTypes {
  ValueDoesNotExist,
  MissingKeys,
  WrongValueType,
}

export interface UiSchemaError {
  message: string;
  type: ErrorTypes;
}

export interface ValidatorResult {
  isValid: boolean;
  errors: UiSchemaError[];
}

export interface Validator {
  validate: () => ValidatorResult;
  setSchema: (schema: RJSFSchema) => void;
  setUiSchema: (uiSchema: UiSchema) => void;
  validateWidgets: () => void;
  validateFields: () => void;
  validateLayout: () => void;
  reset: () => void;
  getErrors: () => UiSchemaError[];
}

export class UiSchemaValidator implements Validator {
  private static instance: UiSchemaValidator;
  private errors: UiSchemaError[] = [];
  private widgets?: string[];
  private whiteListedWidgets?: string[];

  private constructor(private uiSchema: UiSchema, private schema?: RJSFSchema) {}

  static getInstance(
    uiSchema: UiSchema,
    schema?: RJSFSchema,
    widgets?: string[],
    whiteListedWidgets?: string[]
  ): UiSchemaValidator {
    if (this.instance) {
      this.instance.setUiSchema(uiSchema);
      this.instance.setSchema(schema ?? {});
      this.instance.setWidgets(widgets ?? []);
      this.instance.setWhiteListedWidgets(whiteListedWidgets ?? []);
      return this.instance;
    }
    this.instance = new this(uiSchema);
    return this.instance;
  }

  setSchema(schema: RJSFSchema): void {
    this.schema = schema;
  }

  setUiSchema(uiSchema: UiSchema): void {
    this.uiSchema = uiSchema;
  }

  setWidgets(widgets: string[]): void {
    this.widgets = widgets;
  }

  setWhiteListedWidgets(widgets: string[]): void {
    this.whiteListedWidgets = widgets;
  }

  validate(): ValidatorResult {
    this.errors = [];
    this.validateWidgets();
    this.validateFields();
    this.validateLayout();
    return {
      isValid: this.errors.length === 0,
      errors: this.errors,
    };
  }

  reset(): void {
    this.uiSchema = {};
    this.schema = {};
    this.errors = [];
  }

  getErrors(): UiSchemaError[] {
    return this.errors;
  }

  validateWidgets(): void {
    const VALID_NAMES: readonly string[] = Object.values(WidgetNames as unknown as string[]).concat(WhiteListedWidgets);

    const names = this._recursive_search<string>(this.uiSchema, "ui:widget", []);
    const invalidNames = this._validate_names(VALID_NAMES as string[], names);
    invalidNames.forEach((name: string) => {
      this._registerError(ErrorTypes.ValueDoesNotExist, `Widget name does not exist: '${name}'`);
    });
  }

  validateFields(): void {
    const VALID_NAMES: readonly string[] = Object.values(FieldNames as unknown as string[]).concat(WhiteListedFields);

    const names = this._recursive_search<string>(this.uiSchema, "ui:field", []);
    const invalidNames = this._validate_names(VALID_NAMES as string[], names);
    invalidNames.forEach((name: string) => {
      this._registerError(ErrorTypes.ValueDoesNotExist, `Field name does not exist: '${name}'`);
    });
  }

  validateLayout(): void {
    const templateLayouts = this._recursive_search<TemplateLayout>(this.uiSchema, "ui:layout", []);
    const ALL_TEMPLATES: readonly string[] = [
      ...Object.values(ArrayFieldLayoutTemplate),
      ...Object.values(ObjectFieldTemplateLayout),
    ];
    for (const layout of templateLayouts) {
      if (!layout?.template) {
        this._registerError(
          ErrorTypes.MissingKeys,
          `ui:layout requires a template with key 'template' and string value to work`
        );
        continue;
      }
      if (ALL_TEMPLATES.includes(layout.template)) {
        this._validateLayoutTemplate(layout);
      } else {
        this._registerError(
          ErrorTypes.ValueDoesNotExist,
          `Template: '${layout.template}' in ui:layout.template does not exist`
        );
      }
    }
  }

  private _registerError(error: ErrorTypes, message: string): void {
    this.errors.push({
      type: error,
      message,
    });
  }

  private _validateLayoutTemplate(layout: TemplateLayout): void {
    const POSSIBLE_LAYOUT: Required<TemplateLayout> = {
      template: ObjectFieldTemplateLayout.SECTION,
      fields: ["exampleField1", "exampleField2"],
      templateOptions: {
        counter: true,
        formTabs: [
          {
            label: "testLabel",
            icon: "domain",
            fields: ["example", "test"],
          },
        ],
        default: {
          field: "exampleField",
          expanded: true,
        },
      },
    };
    this._validateObjectByReference(layout, POSSIBLE_LAYOUT);
  }

  private _validateObjectByReference = (candidate: any, reference: any): void => {
    if (!isObject(candidate)) return;
    Object.entries(candidate).forEach(([key, value]) => {
      if (!(key in reference)) {
        this._registerError(ErrorTypes.MissingKeys, `'${key}' is not a valid key. Ref: '${JSON.stringify(candidate)}'`);
      }
      if (typeof value !== typeof reference[key]) {
        this._registerError(
          ErrorTypes.WrongValueType,
          `Type of key '${key}' is not valid. Should be '${typeof reference[key]}'. Gave: '${typeof value}'`
        );
      }
      if (isObject(value)) {
        this._validateObjectByReference(value, reference?.[key]);
      }
    });
  };

  private _validate_names(validNames: readonly string[], names: string[]): string[] {
    const invalid_names: string[] = [];
    names.forEach((name: string) => {
      if (!validNames.includes(name)) invalid_names.push(name);
    });
    return invalid_names;
  }

  private _recursive_search<T>(obj: any, attr: string, results: T[]): T[] {
    const _results = results;
    Object.keys(obj).forEach((key: string) => {
      const value = obj[key];
      if (key === attr) {
        _results.push(value);
      }
      if (typeof value === "object") {
        this._recursive_search(value, attr, _results);
      }
    });
    return _results;
  }
}

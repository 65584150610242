import { useCallback, useEffect, useId, useState } from "react";
import type { DefaultModalMeta, ModalNames, ModalStates } from "./types";
import { useModalManager } from "./store";
import { useObjectHasChanged } from "@/hooks/useObjectHasChanged";

export const useModal = <
  ModalName extends ModalNames,
  Props extends ModalStates[ModalName]["props"],
  Meta extends ModalStates[ModalName]["meta"]
>(
  name: ModalName,
  props: Props | ((closeModal: () => void) => Props),
  meta?: Meta | ((closeModal: () => void) => Meta)
) => {
  const modalId = useId();
  const [defaultMeta] = useState<DefaultModalMeta>({
    onClose: () => {
      closeModal(name, modalId);
    },
  });

  const setProps = useModalManager((s) => s.setProps);
  const currentModalId = useModalManager((s) => s.documentListModalContent.currentModalId);
  const isOpen = useModalManager((s) => s[name].isOpen);
  const openModal = useModalManager((s) => s.open);
  const closeModal = useModalManager((s) => s.close);

  const getProps = useCallback(
    () => (typeof props === "function" ? props(() => closeModal(name, modalId)) : props),
    [closeModal, modalId, name, props]
  );

  const getMeta = useCallback<() => DefaultModalMeta>(
    () => ({
      ...defaultMeta,
      ...(typeof meta === "function" ? meta(() => closeModal(name, modalId)) : meta),
    }),
    [closeModal, defaultMeta, meta, modalId, name]
  );

  const propsHaveChanged = useObjectHasChanged(getProps());

  useEffect(() => {
    if (propsHaveChanged) {
      setProps(name, modalId, getProps());
    }
  }, [currentModalId, getProps, modalId, name, props, propsHaveChanged, setProps]);

  const close = useCallback(() => {
    closeModal(name, modalId);
  }, [closeModal, modalId, name]);

  const open = useCallback(() => {
    openModal(name, modalId, getProps(), getMeta());
  }, [getMeta, getProps, modalId, name, openModal]);

  return { open, close, isOpen, modalId };
};
